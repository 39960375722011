import Axios from "./caller.service";

let getAllNotfications = (userId) => {
  return Axios.get(`/api/Notification/${userId}/5`);
};

let updateToRead = (ids) => {
  return Axios.put("/api/Notification/read",  ids );
}

let clearAll = (userId) => {
  const result = Axios.delete( "/api/Notification/clearAll/"+ userId);
  return result;
};
export const notificationService = {
  getAllNotfications,
  updateToRead,
  clearAll
};
