import React from "react";
import Button from "reactstrap/lib/Button";


const Save = (props) => {
  return (
    <Button
      style={{ fontSize: "var(--theme-font-size)" }}
      key={"form" + props.field}
      onClick={(e) => props.handleSave(e)}
      variant="primary"
      className="float-right mx-3 btn btn-success"
      disabled={props.isReadOnly}
    >
      {props.title}
    </Button>
  );
};

export default Save;
