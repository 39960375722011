import React, { useEffect, useState } from "react";
import CustomCarousel from "../../../components/Carousel/Carousel";
import RolesTable from "./Tables/Roles";
import RoleDetail from "./RoleDetail";
import Loader from "../../../components/Loading/Loader";
import {
  getAll,
  get,
  add,
  update,
  remove,
} from "../../../utils/_services/role.service";
import GenericHeader from "components/Headers/GenericHeader";
import { Container } from "reactstrap";
const Roles = () => {
  const [roles, setRoles] = useState([]);
  // const roles = [
  //   {
  //     creationDate: "2022-10-14 13:37:17",
  //     modificationDate: "2024-03-28 11:3:16",
  //     description: "",
  //     id: "9f1decaa-a04c-47ac-ba33-581022521dc4",
  //     name: "role_voucher-business_ops",
  //     permissions: [
  //       "perm_blacklist_user_add",
  //       "perm_blacklist_user_menu",
  //       "perm_campaigns_typology_cs_menu",
  //       "perm_campaigns_typology_cs_upload_vouchers",
  //       "perm_campaigns_typology_staffPortal_activate",
  //       "perm_campaigns_typology_staffPortal_menu",
  //       "perm_campaigns_typology_staffPortal_upload_vouchers",
  //       "perm_offers_activate",
  //       "perm_offers_add",
  //       "perm_offers_menu",
  //       "perm_offers_update",
  //       "perm_voucher_delete",
  //       "perm_voucher_file",
  //       "perm_voucher_search_manager",
  //       "perm_vouchers_brand_all",
  //       "perm_vouchers_realm_all",
  //       "perm_vouchers_typology_cs_menu",
  //       "perm_vouchers_typology_staffPortal_menu",
  //     ],
  //   },
  //   {
  //     creationDate: "2024-08-23 13:6:12",
  //     modificationDate: "2024-11-6 11:46:48",
  //     description: "",
  //     id: "e55318e6-3d13-4a5c-aa1a-4b4261e1cf40",
  //     name: "role_flag-issue_cs-team_voucher-cs",
  //     permissions: [
  //       "manage",
  //       "perm_campaigns_typology_cs_activate",
  //       "perm_campaigns_typology_cs_menu",
  //       "perm_campaigns_typology_cs_upload_vouchers",
  //       "perm_downloader",
  //       "perm_editor",
  //       "perm_flag-issue_brand_all",
  //       "perm_flag-issue_category_activate",
  //       "perm_flag-issue_category_add",
  //       "perm_flag-issue_category_delete",
  //       "perm_flag-issue_category_translation_add",
  //       "perm_flag-issue_category_translation_delete",
  //       "perm_flag-issue_category_translation_update",
  //       "perm_flag-issue_category_update",
  //       "perm_flag-issue_dashboard-graphs_tab",
  //       "perm_flag-issue_dashboard-incidents_tab",
  //       "perm_flag-issue_dashboard_menu",
  //       "perm_flag-issue_incident-graphs_menu",
  //       "perm_flag-issue_locale_all",
  //       "perm_flag-issue_menu",
  //       "perm_flag-issue_single_request_menu",
  //       "perm_flag-issue_subcategory_activate",
  //       "perm_flag-issue_subcategory_add",
  //       "perm_flag-issue_subcategory_translation_activate",
  //       "perm_flag-issue_subcategory_translation_add",
  //       "perm_flag-issue_subcategory_translation_delete",
  //       "perm_flag-issue_subcategory_translation_update",
  //       "perm_flag-issue_subcategory_update",
  //       "perm_rap_dashboard",
  //       "perm_uploader",
  //       "perm_viewer",
  //       "perm_voucher_file",
  //       "perm_voucher_search_user",
  //       "perm_vouchers_brand_all",
  //       "perm_vouchers_realm_dplay",
  //       "perm_vouchers_realm_dplusapac",
  //       "perm_vouchers_realm_dplusindia",
  //       "perm_vouchers_realm_eurosport",
  //       "perm_vouchers_typology_cs_menu",
  //     ],
  //   },
  //   {
  //     creationDate: "2024-08-23 11:6:12",
  //     modificationDate: "2024-11-6 11:39:47",
  //     description: "",
  //     id: "8618b289-d9dc-475f-b97a-a98399541dc6",
  //     name: "role_cs-team_voucher-business_ops",
  //     permissions: [
  //       "manage",
  //       "perm_blacklist_user_add",
  //       "perm_blacklist_user_menu",
  //       "perm_campaigns_typology_cs_menu",
  //       "perm_campaigns_typology_cs_upload_vouchers",
  //       "perm_campaigns_typology_staffPortal_activate",
  //       "perm_campaigns_typology_staffPortal_menu",
  //       "perm_campaigns_typology_staffPortal_upload_vouchers",
  //       "perm_downloader",
  //       "perm_editor",
  //       "perm_offers_activate",
  //       "perm_offers_add",
  //       "perm_offers_menu",
  //       "perm_offers_update",
  //       "perm_uploader",
  //       "perm_viewer",
  //       "perm_voucher_delete",
  //       "perm_voucher_file",
  //       "perm_voucher_search_manager",
  //       "perm_voucher_search_user",
  //       "perm_vouchers_brand_all",
  //       "perm_vouchers_realm_all",
  //       "perm_vouchers_typology_cs_menu",
  //       "perm_vouchers_typology_staffPortal_menu",
  //     ],
  //   },
  //   {
  //     creationDate: "2024-09-3 19:54:47",
  //     modificationDate: "2024-11-6 11:16:38",
  //     description: "",
  //     id: "b5744b9f-6b52-4c78-b049-c90a84b07d2c",
  //     name: "role_flag-issue_read-only",
  //     permissions: [
  //       "perm_flag-issue_assetView_access",
  //       "perm_flag-issue_brand_all",
  //       "perm_flag-issue_criteria_menu",
  //       "perm_flag-issue_dashboard-graphs_tab",
  //       "perm_flag-issue_dashboard-incidents_tab",
  //       "perm_flag-issue_locale_all",
  //       "perm_flag-issue_market_menu",
  //       "perm_flag-issue_menu",
  //       "perm_flag-issue_single_requests_menu",
  //       "perm_flag-issue_triggers_menu",
  //       "perm_rap_dashboard",
  //     ],
  //   },
  //   {
  //     creationDate: "1970-01-20 11:53:59",
  //     modificationDate: "2024-08-29 9:48:13",
  //     description: "",
  //     id: "02a9b38f-72bd-4487-87b2-9a9d8dc7b8a7",
  //     name: "role_file-manager_wbd-exchanges_cs-team",
  //     permissions: [
  //       "perm_downloader",
  //       "perm_uploader",
  //     ],
  //   },
  //   {
  //     creationDate: "2024-08-23 7:6:12",
  //     modificationDate: "2024-11-6 11:34:26",
  //     description: "",
  //     id: "08773339-afb1-4673-a1f4-b756e0b1ec65",
  //     name: "role_flag-issue_cs-team",
  //     permissions: [
  //       "manage",
  //       "perm_downloader",
  //       "perm_editor",
  //       "perm_flag-issue_brand_all",
  //       "perm_flag-issue_category_activate",
  //       "perm_flag-issue_category_add",
  //       "perm_flag-issue_category_delete",
  //       "perm_flag-issue_category_translation_add",
  //       "perm_flag-issue_category_translation_update",
  //       "perm_flag-issue_category_update",
  //       "perm_flag-issue_dashboard-graphs_tab",
  //       "perm_flag-issue_dashboard-incidents_tab",
  //       "perm_flag-issue_dashboard-incidents_tab_actions",
  //       "perm_flag-issue_dashboard_menu",
  //       "perm_flag-issue_incident-graphs_menu",
  //       "perm_flag-issue_locale_all",
  //       "perm_flag-issue_menu",
  //       "perm_flag-issue_single_request_menu",
  //       "perm_flag-issue_single_requests_menu",
  //       "perm_flag-issue_subcategory_activate",
  //       "perm_flag-issue_subcategory_add",
  //       "perm_flag-issue_subcategory_translation_activate",
  //       "perm_flag-issue_subcategory_translation_add",
  //       "perm_flag-issue_subcategory_translation_update",
  //       "perm_flag-issue_subcategory_update",
  //       "perm_rap_dashboard",
  //       "perm_uploader",
  //       "perm_viewer",
  //     ],
  //   },
  //   {
  //     creationDate: "1970-01-20 12:53:59",
  //     modificationDate: "2024-07-1 14:12:49",
  //     description: "",
  //     id: "ceae4f18-88b1-478c-89c6-9c540782c6f5",
  //     name: "role_file-manager_wbd-exchanges_cs-management",
  //     permissions: [
  //       "perm_downloader",
  //       "perm_flag-issue_brand_all",
  //       "perm_uploader",
  //     ],
  //   },
  //   {
  //     creationDate: "2022-10-14 11:39:14",
  //     modificationDate: "2024-07-11 14:50:43",
  //     description: "",
  //     id: "ddc772b5-f55b-4e68-aa26-0eabb6a805ac",
  //     name: "role_flag-issue",
  //     permissions: [
  //       "perm_flag-issue_assetView_access",
  //       "perm_flag-issue_brand_all",
  //       "perm_flag-issue_category_activate",
  //       "perm_flag-issue_category_add",
  //       "perm_flag-issue_category_delete",
  //       "perm_flag-issue_category_translation_add",
  //       "perm_flag-issue_category_translation_delete",
  //       "perm_flag-issue_category_translation_update",
  //       "perm_flag-issue_category_update",
  //       "perm_flag-issue_locale_all",
  //       "perm_flag-issue_menu",
  //       "perm_flag-issue_subcategory_activate",
  //       "perm_flag-issue_subcategory_add",
  //       "perm_flag-issue_subcategory_delete",
  //       "perm_flag-issue_subcategory_translation_add",
  //       "perm_flag-issue_subcategory_translation_delete",
  //       "perm_flag-issue_subcategory_translation_update",
  //       "perm_flag-issue_subcategory_update",
  //       "perm_flag-issue_translation_activate",
  //       "perm_rap_dashboard",
  //     ],
  //   },
  //   {
  //     creationDate: "2022-10-14 15:38:45",
  //     modificationDate: "2023-03-15 16:11:13",
  //     description: "",
  //     id: "bb92f701-d03e-4fec-8b30-636dd17ca565",
  //     name: "role_voucher-cs",
  //     permissions: [
  //       "perm_campaigns_typology_cs_activate",
  //       "perm_campaigns_typology_cs_menu",
  //       "perm_campaigns_typology_cs_upload_vouchers",
  //       "perm_vouchers_brand_all",
  //       "perm_vouchers_realm_intl",
  //       "perm_vouchers_typology_cs_menu",
  //       "perm_voucher_search_user",
  //       "perm_voucher_file",
  //     ],
  //   },
  // ];
  const [selectedRoleIndex, setSelectedRoleIndex] = useState(-1);
  const [activeSlide, setActiveSlide] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const rolesSlideIndex = 0;
  const roleSlideIndex = 1;
  const nextSlide = () => setActiveSlide(activeSlide < 1 ? activeSlide + 1 : activeSlide);
  const previousSlide = () => setActiveSlide(activeSlide > 0 ? activeSlide - 1 : activeSlide);

  useEffect(() => {
    setIsLoading(true);
    loadRoles();
  }, []);

  const loadRoles = async () => {
    const result = await getAll();
    setRoles(result.data.result.data);
    setIsLoading(false);
  };

  const onRowClick = async (index) => {
    const result = await get(roles[index].id);
    const editedRoles = [...roles];
    editedRoles[index].permissions =
      result.length > 0 ? result.map((r) => r.name) : [];
    setRoles(editedRoles);
    setSelectedRoleIndex(index);
    nextSlide();
  };

  const onSave = async (index, data) => {
    const editedRoles = [...roles];

    if (index > -1) {
      const result = await update({
        roleId: editedRoles[index].id,
        permissions: data,
      });
      if (result) {
        editedRoles[index].permissions = data;
        setRoles(editedRoles);
      }
    } else {
      const result = await add(data);
      if (result) {
        editedRoles.push(result);
        setRoles(editedRoles);
        setSelectedRoleIndex(editedRoles.length - 1);
      }
    }
  };

  const onDeleteClick = async (index) => {
    await remove(roles[index].id);
    const editedRoles = [...roles];
    editedRoles.splice(index, 1);
    setRoles(editedRoles);
  };

  const items = [
    {
      id: rolesSlideIndex,
      component: (
        <RolesTable
          roles={roles}
          handleRowClick={onRowClick}
          handleDeleteClick={onDeleteClick}
          handleSave={onSave}
        />),
    },
    {
      id: roleSlideIndex,
      component: (
        <RoleDetail
          index={selectedRoleIndex}
          role={roles[selectedRoleIndex]}
          handleSave={onSave}
          handleBackClick={previousSlide}
        />
      ),
    },
  ];

  return (
    <>
      <GenericHeader />
      <Container style={{ maxWidth: "2000px", marginTop: "25px" }}>
        {/* <Loader> */}
        <CustomCarousel items={items} activeIndex={activeSlide} />
        {/* </Loader> */}
      </Container>
    
    </>
  );
};

export default Roles;
