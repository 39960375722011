/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "reactstrap";
import { RenderForm } from "./forms";
import { checkValidity } from "../../utils/input";

const FormGenerator = (props) => {
  const { index, form, data, calculatedData, handleChange, handleSave, handleCancel, handleFunctions, style = {} } = props;
  const [editedForm, setForm] = useState(form);
  const [editedData, setData] = useState(data);
  const inputs = []

  useEffect(() => {
    setForm(form);
  }, [form]);

  useEffect(() => {
    setData(data);
  }, [data]);

  useEffect(() => {
    const obj = handleChange ? { ...data } : { ...editedData };
    setData(Object.assign(obj, { ...calculatedData }));
  }, [calculatedData]);

  const onChange = async (field, value) => {
    if (handleChange) {
      handleChange(field, value);
    } else {
      setForm(form);
      let changedData = { ...editedData };
      if (handleFunctions && handleFunctions[field]) {
        const result = await handleFunctions[field](value);
        Object.assign(changedData, result);
      } else {
        changedData[field] = value;
      }

      setData(changedData);
    }
  };

  const validateForm = (form) => {
    return {
      rows: form.rows.map(row => ({
        columns: row.columns.map(column => ({ ...column, ...{ render: { ...column.render, ...{ dirty: true } } } }))
      })),
      footer: form.footer
    }
  }

  const onSubmit = (event) => {
    const target = event.currentTarget;
    // const inputs = [];
    // form.rows.map((row, i) => row.columns.map((column) => inputs.push(column.render)))
    const obj = handleChange ? { ...data } : { ...editedData };
    if (target.checkValidity() === false || !checkValidity(inputs, obj)) {
      event.stopPropagation();
      setForm(validateForm(editedForm))
    } else {
      if (handleSave) handleSave(index, obj);
    }
    event.preventDefault();
  };

  return (
    <Form noValidate onSubmit={onSubmit}>
      <div
        style={{
          ...{
            maxHeight: "calc(100vh - 210px)",
            overflowY: "auto",
            overflowX: "hidden",
          }, ...style
        }}
      >
        {editedForm.rows.map((row, i) => {
          return (
            <Row key={"form" + i}>
              {row.columns.map((column) => {
                inputs.push(column.render);
                return (
                  <Col key={"form" + column.render.field} md={column.size}>
                    <RenderForm
                      form={column.render}
                      value={handleChange ? data[column.render.field] : editedData[column.render.field]}
                      handleChange={onChange}
                    />
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </div>
      {editedForm.footer && (
        <React.Fragment>
          <React.Fragment>
            <hr />
          </React.Fragment>
          <React.Fragment>
            <Row>
              <Col md={12}>
                {editedForm.footer.map((f) => {
                  return (
                    <React.Fragment key={"form" + f.render.title}>
                      <RenderForm
                        form={f.render}
                        handleCancel={() => handleCancel()}
                      />
                    </React.Fragment>
                  );
                })}
              </Col>
            </Row>
          </React.Fragment>
        </React.Fragment>
      )}
    </Form>
  );
};

export default FormGenerator;
