import React, { useEffect, useRef, useState } from "react";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import {
  RiBuilding4Fill,
  RiGroupFill,
  RiMoneyEuroCircleLine,
  RiSmartphoneFill,
  RiRegisteredFill,
} from "react-icons/ri";
import "./profil.css";
import MyCarousel from "components/Ui/MyCarousel";
import defaultProfilePicture from "assets/img/brand/ImageNotFound.png";
import facebookIcon from "assets/img/icons/facebook.png";
import websiteIcon from "assets/img/icons/web.png";
import linkedinIcon from "assets/img/icons/linkedin.png";
import instagramIcon from "assets/img/icons/instagram.png";
import { capitalizeFirstLowercaseRest } from "utils/_helpers/stringUtils";
import { reviewsService } from "utils/_services/review.service";
import ReviewList from "components/Review/ReviewList";
import CheckboxesGroup from "./CheckboxesGroup";
import { useSelector } from "react-redux";
import { getAllworkAvailabilities } from "features/slices/workAvailabilitySlice";

const groupObjects = (data) => {
  if (data) {
    const groupedItems = {};
    data.forEach((item) => {
      let { familyTitle = "Divers" } = item;
      if (familyTitle === null) {
        familyTitle = "Divers";
      }
      if (!groupedItems[familyTitle]) {
        groupedItems[familyTitle] = [];
      }
      groupedItems[familyTitle].push(item);
    });
    return groupedItems;
  } else {
    return {};
  }
};

const renderItem = (item, index) => {
  return (
    <div
      key={index}
      className="card col-xs-12 img-fluid"
      style={{
        width: "20rem",
        boxShadow: "0 4px 18px 0 rgb(219 219 219 / 50%)",
        borderRadius: "1.875rem",
        marginLeft: "1rem",
        height: "13rem",
        overflow: "hidden", // Ajout de cette propriété pour cacher le contenu débordant
      }}
    >
      <img
        src={item.url}
        alt="avatar"
        style={{ objectFit: "cover", width: "100%", height: "100%" }}
      />
    </div>
  );
};

const ViewProfile = ({ searchedUser }) => {

  const [skills, setSkills] = useState();
  const [reviews, setReviews] = useState([]);
  const [reviewsAverage, setReviewsAverage] = useState(0);
  const ratingsRef = useRef();
  const projectsRef = useRef();
  const workAvailabilities = useSelector(getAllworkAvailabilities);

  useEffect(() => {
    setReviewsAverage(0);
    setSkills(groupObjects(searchedUser.skills));
    reviewsService
      .getReviewsByCompanyId(searchedUser.company?.id)
      .then((response) => {
        const companyReviews = response.data;
        setReviews(companyReviews);
        if (companyReviews.length > 0) {
          const avg = companyReviews.reduce((a, b) => a + b.rating, 0) / companyReviews.length;
          const avgRounded = avg.toFixed(1);
          setReviewsAverage(avgRounded)
        }
      })
      .catch((err) => console.log(err));
  }, [searchedUser]);

  const scrollToRatings = () => {
    if (ratingsRef.current) {
      ratingsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToProjects = () => {
    if (projectsRef.current) {
      projectsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="view-public-profile container">
      <div className="bg-profile"></div>
      <Card className="shadow position-profile p-1">
        <CardBody className="p-1">
          <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
            <div className="p-2 d-flex flex-row">
              {" "}
              <img
                src={searchedUser.photo?.url ?? defaultProfilePicture}
                alt="avatar"
                id="profilePicture"
                className="rounded img-fluid w-full h-full"
                style={{
                  display: "block",
                  maxWidth: "70px",
                }}
              />
              <div className="m-2">
                <h5 className="title-profile">
                  <i className="fa fa-user mr-2" aria-hidden="true"></i>{" "}
                  {searchedUser.firstName} {searchedUser.lastName}
                </h5>
                <span>{searchedUser.job?.title ?? "Non renseigné"}</span>
              </div>
            </div>

            <div className="p-2">
              <h5>
                <RiSmartphoneFill
                  className="mr-2 mb-1"
                  // fontSize="1rem"
                  color="rgb(111,174,222)"
                />
                <span className="company-titles">{searchedUser.phoneNumber ?? "Non renseigné"}</span>
              </h5>
            </div>
            <div className="p-2">
              <h5>
                <i
                  className="fa fa-map-marker-alt mr-2"
                  aria-hidden="true"
                ></i>{" "}
                {searchedUser.commune?.name ?? "Non rensignée"}
              </h5>
            </div>
          </div>
        </CardBody>
      </Card>
      <div style={{ top: "-70px", position: "relative" }}>
        <Row >
          <Col md="12" lg="12">
            <Card className="shadow mb-2">
              <CardBody className="d-flex flex-column flex-sm-row justify-content-start align-items-center">

                <div className="cursor-pointer" onClick={scrollToRatings}>
                  <u>
                    <span className="review-stars">{'★'}</span>
                    <span><b>{reviewsAverage} - {reviews.length} Avi(s)</b></span>
                  </u>
                </div>
                <div className="cursor-pointer ml-4" onClick={scrollToProjects}>
                  <u>
                    <b>Galerie</b>
                  </u>
                </div>

              </CardBody>
            </Card>
          </Col>
        </Row>


        <Row >
          <Col md="12" lg="12" >
            <Card className="shadow mb-2">
              <CardHeader>
                <h2>Présentation</h2>
              </CardHeader>
              <CardBody>{searchedUser.howIAmDescription ?? "Non renseignée"}</CardBody>
            </Card>

            {/* <Card className="shadow mb-2">
            <CardHeader>
              <h2>Ce que j'aime</h2>
            </CardHeader>
            <CardBody>{searchedUser.hobbies}</CardBody>
          </Card> */}
          </Col>
        </Row>
        <Row>
          <Col md="12" lg="12" >
            <Card className="shadow mb-2">
              <CardHeader>
                <h2>Mon entreprise</h2>
              </CardHeader>
              <CardBody>
                <Row className="mb-3">
                  <Col md="6" lg="6">
                    <span>Entreprise - </span>
                    <RiBuilding4Fill
                      className="mr-2 mb-1"
                      // fontSize="1rem"
                      color="rgb(111,174,222)"
                    />
                    <span className="company-titles">
                      {" "}
                      {searchedUser.company?.name}
                    </span>
                  </Col>
                  <Col md="6" lg="6">
                    <span>Siret - </span>
                    <RiRegisteredFill
                      className="mr-2 mb-1"
                      // fontSize="1rem"
                      color="rgb(111,174,222)"
                    />
                    <span className="company-titles">
                      {searchedUser.company?.siretNumber ?? "Non renseigné"}
                    </span>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md="6" lg="6">
                    <span>Chiffre d'affaire - </span>
                    <RiMoneyEuroCircleLine
                      className="mr-2 mb-1"
                      // fontSize="1rem"
                      color="rgb(111,174,222)"
                    />
                    <span className="company-titles">
                      {searchedUser.company?.turnover ?? "Non renseigné"}
                    </span>
                  </Col>
                  <Col md="6" lg="6">
                    <span>Nombre d'employés - </span>
                    <RiGroupFill
                      className="mr-2 mb-1"
                      // fontSize="1rem"
                      color="rgb(111,174,222)"
                    />
                    <span className="company-titles">
                      {searchedUser.company?.employeesNumber ?? "Non renseigné"}
                    </span>
                  </Col>
                </Row>
                <Row className="mb-3">
                  {skills ?
                    Object.entries(skills).map(
                      ([familyTitle, items]) => {
                        return (
                          <Col md="6" lg="6">
                            <h4 >{familyTitle}</h4>
                            <ul>
                              {items.map((item, index) => (
                                <li key={index}>{item.description}</li>
                              ))}
                            </ul>
                          </Col>
                        );
                      }
                    ) : "Savoir faire non renseigné"}

                  <Col md="6" lg="6">
                    <h4>Assurances</h4>
                    <ul>
                      {searchedUser.company?.rcpInsurance === true &&
                        <li key={"rcpInsurance"}>
                          Assurance RCP et décennale
                        </li>}
                      {searchedUser.company?.insurances
                        && searchedUser.company.insurances.map((value, index) => <li key={`insurance-${index}`}>{value.title}</li>)}
                    </ul>
                    {searchedUser.company?.rcpInsurance !== true && (!searchedUser.company?.insurances || searchedUser.company?.insurances.length === 0) && <div>Pas d'assurance renseignée</div>}
                  </Col>
                </Row>

                <div>
                  <h4>Nos liens</h4>
                  <a
                    href={searchedUser.company?.websiteLink || "#"}
                    title={searchedUser.company?.websiteLink ? 'Notre site' : "Site Web non renseigné"}
                    className={searchedUser.company?.websiteLink ? 'cursor-pointer' : 'disabled-link'}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => {
                      if (!searchedUser.company?.websiteLink) {
                        e.preventDefault(); // Prevent navigation if no Facebook link
                      }
                    }}
                  >
                    <img
                      src={websiteIcon}
                      alt="SiteWeb"
                      className={`m-2 ${!searchedUser.company?.websiteLink ? 'disabled-icon' : ''}`} // Conditionally add 'disabled-icon' class"
                      width="50px"
                      height="50px"
                    ></img>
                  </a>
                  <a
                    href={searchedUser.company?.facebookLink || "#"}
                    title={searchedUser.company?.facebookLink ? 'Notre Facebook' : "Facebook non renseigné"}
                    className={searchedUser.company?.facebookLink ? 'cursor-pointer' : 'disabled-link'}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => {
                      if (!searchedUser.company?.facebookLink) {
                        e.preventDefault(); // Prevent navigation if no Facebook link
                      }
                    }}
                  >
                    <img
                      src={facebookIcon}
                      alt="Facebook"
                      className={`m-2 ${!searchedUser.company?.facebookLink ? 'disabled-icon' : ''}`} // Conditionally add 'disabled-icon' class"
                      width="50px"
                      height="50px"
                    ></img>
                  </a>
                  <a
                    href={searchedUser.company?.linkedinLink || "#"}
                    title={searchedUser.company?.linkedinLink ? 'Notre Linkedin' : 'Linkedin non renseigné'}
                    className={searchedUser.company?.linkedinLink ? 'cursor-pointer' : 'disabled-link'} // Conditionally add 'disabled-link' class
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => {
                      if (!searchedUser.company?.linkedinLink) {
                        e.preventDefault(); // Prevent navigation if no Linkedin link
                      }
                    }}
                  >
                    <img
                      src={linkedinIcon}
                      alt="Linkedin"
                      className={`m-2 ${!searchedUser.company?.linkedinLink ? 'disabled-icon' : ''}`} // Conditionally add 'disabled-icon' class"
                      width="50px"
                      height="50px"
                    ></img>
                  </a>
                  <a
                    href={searchedUser.company?.instagramLink || "#"} // Use "#" or no href when disabled
                    title={searchedUser.company?.instagramLink ? 'Notre Instagram' : 'Instagram non renseigné'}
                    className={`cursor-pointer ${!searchedUser.company?.instagramLink ? 'disabled-link' : ''}`} // Conditionally add 'disabled-link' class
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => {
                      if (!searchedUser.company?.instagramLink) {
                        e.preventDefault(); // Prevent navigation if no Instagram link
                      }
                    }}
                  >
                    <img
                      src={instagramIcon}
                      alt="Instagram"
                      className={`m-2 ${!searchedUser.company?.instagramLink ? 'disabled-icon' : ''}`} // Conditionally add 'disabled-icon' class
                      width="50px"
                      height="50px"
                    ></img>
                  </a>

                </div>
              </CardBody>
            </Card>

            <Card className="shadow mb-2">
              <CardHeader>
                <h2>Nos disponibilités</h2>
              </CardHeader>
              <CardBody>
                {searchedUser.workAvailabilities &&
                  searchedUser.workAvailabilities.length > 0 ? (
                  <CheckboxesGroup
                    defaultItems={workAvailabilities}
                    selectedItems={searchedUser.workAvailabilities}
                    onChange={() => { }}
                    readOnly={true}
                  />) : "Non renseignées"}
                  {!searchedUser.workAvailabilities && "Non renseignées"}
              </CardBody>
            </Card>

          </Col>
        </Row>

        <Row >
          <Col md="12" lg="12" style={{ paddingLeft: "15px" }}>
            <Card className="shadow mb-2">
              <CardHeader>
                <h2 ref={ratingsRef} >Avis de l'entreprise {capitalizeFirstLowercaseRest(searchedUser.company?.name)}</h2>
              </CardHeader>
              <CardBody>
                {reviews && reviews.length > 0 ? <ReviewList reviews={reviews} /> : "Aucun avis de l'entreprise"}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12" lg="12">
            <Card className="shadow mb-2">
              <CardHeader>
                <h2 ref={projectsRef}>Galerie</h2>
              </CardHeader>
              <CardBody>
                {searchedUser.userWorkPhotos && searchedUser.userWorkPhotos.length > 0 ? (
                  <MyCarousel
                    infinite={true}
                    autoplay={false}
                    autoplaySpeed={3000}
                    data={searchedUser.userWorkPhotos}
                    renderItem={renderItem}
                  />
                ) : "Aucune photo de l'entreprise"}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ViewProfile;
