import { Link } from "react-router-dom";

import { userService } from "utils/_services/user.service";

import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
} from "reactstrap";

const DropDownNavbarMenu = (props) => {

  const { currentUser } = props;
  const logout = () => {
    userService.doLogout();
  };


  return (
    <UncontrolledDropdown nav>
      <DropdownToggle className="pr-0" nav>
        <Media className="align-items-center">
          <span className="avatar avatar-sm rounded-circle">
            <img
              alt="..."
              src={
                currentUser?.photo
                  ? currentUser.photo.url
                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFgYa-_gqC96dLuycec7O08nv9NE8CH5FycVBp_Gl96g&s"
              }
            />
          </span>
          <Media className="ml-2 d-none d-lg-block">
            <span className="mb-0 text-sm font-weight-bold text-white">
              {currentUser.firstName} {currentUser.lastName}
            </span>
          </Media>
        </Media>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-arrow" right>
        <DropdownItem className="noti-title" header tag="div">
          <h6 className="text-overflow m-0">Bienvenue!</h6>
        </DropdownItem>
        <DropdownItem to={process.env.REACT_APP_WELCOME_PAGE_PATH} tag={Link}>
          <i className="ni ni-single-02" />
          <span>Mon profil</span>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={logout}>
          <i className="ni ni-user-run" />
          <span>Déconnexion</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>);
}


export default DropDownNavbarMenu;

