import { ALL } from "../constants/subjects";
import { getAuthorizedMenu } from "../../utils/authorization";

export const getAuthorizedRoutes = (allRoutes, userPermissions) => {
  const authorizedMenu = getAuthorizedMenu(userPermissions);
  return filterByAuthorizedMenu(allRoutes, authorizedMenu);
};

const filterByAuthorizedMenu = (routes, authorizedRoutes) => {
  let restrictedRoutes = [];
  if (authorizedRoutes.length === 0) return restrictedRoutes;
  if (authorizedRoutes.includes(ALL)) return routes;

  return filterByAuthorizedChildrens(routes, authorizedRoutes);
};

const filterByAuthorizedChildrens = (allroutes, authorizedRoutes) => {
  if (
    !allroutes ||
    allroutes.length === 0 ||
    !authorizedRoutes ||
    authorizedRoutes.length === 0
  )
    return [];

  const filteredRoutes = authorizedRoutes.map((authorizedRoute) => {
    const route = allroutes.find((r) => r && (r.id === authorizedRoute.id && r.showInMenu === true));
    return checkRoute(route, authorizedRoute);
  });

  var hiddenRoutes = allroutes.filter((r) => r && r.showInMenu === false);
  hiddenRoutes.map((r) => filteredRoutes.push(r));

  return filteredRoutes;
};

const checkRoute = ((route, authorizedRoute) => {
  if (route) {
    if (
      route.childrens &&
      route.childrens.length > 0 &&
      authorizedRoute.childrens &&
      authorizedRoute.childrens.length > 0
    )
      return {
        ...route,
        ...{
          childrens: filterByAuthorizedChildrens(
            route.childrens,
            authorizedRoute.childrens
          ),
        },
      };
    else return { ...route, ...{ childrens: [] } };
  }

  return [];
})