import * as subjects from "../../constants/subjects";
import * as permissions from "../constants/permissions";
import { CanHandle } from "./common";

export const defineRulesForAccessRoles = (can, roles) => {
    if ([permissions.ALL, permissions.BTPGO_ADMIN].some((role) => roles.includes(role))) {
        can(permissions.MANAGE, subjects.ROLES);
        can(permissions.MANAGE, subjects.USERS);
        can(permissions.MANAGE, subjects.PROFILE);
        can(permissions.MANAGE, subjects.SOCIAL);
    } else {
        roles.forEach((perm) => {
            can(perm, subjects.ROLES);
            can(perm, subjects.USERS);
        });
    }
};

export const CanHandleRoles = () => {
    return CanHandle(permissions.ROLE_MANAGE, subjects.ROLES);   
}

export const CanHandleUsers = () => {
    return CanHandle(permissions.USER_MANAGE, subjects.USERS);
}

export const CanHandlePricePlan = () => {
    return CanHandle(permissions.PRICEPLAN_ROLES_MANAGE, subjects.PRICEPLAN_ROLES);
}
