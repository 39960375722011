import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

export const textInput = (field, isRequired, isReadOnly, placeholder, value, handleChange, style) => {
  return <Input
    id={field}
    style={style}
    key={field}
    type="text"
    required={isRequired}
    value={value ? value : ""}
    onChange={(e) => handleChange(field, e.target.value)}
    disabled={isReadOnly}
    placeholder={placeholder}
  />

}
export default function Text(props) {
  const {
    field,
    title,
    isRequired,
    value,
    isReadOnly,
    placeholder,
    handleChange,
    style,
  } = props;
  return (
    <FormGroup key={"form" + field} controlid={field}>
      {title && <Label for={field}>{isRequired ? title + "*" : title}</Label>}
      {textInput(field, isRequired, isReadOnly, placeholder, value, handleChange, style)}
    </FormGroup>
  );
}
