import * as React from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

export default function ScrolledTabs(props) {
    const { childrens } = props;

    const [value, setValue] = React.useState(0);

    const handleChange = ( newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Nav tabs>
                {childrens.map(({ title }, index) => (
                    <NavItem key={index}>
                        <NavLink
                            className={`${value === index ? "active font-weight-bold" : ""} cursor-pointer mr-3`}
                            onClick={() => handleChange(index)} // Pass index directly
                        >
                            {title}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            <TabContent className="mt-4" activeTab={value}>
                {childrens.map(({ component }, index) => (
                    <TabPane key={index} tabId={index}>
                        {value === index && component} {/* Ensure only active tab content is rendered */}
                    </TabPane>
                ))}
            </TabContent>
        </div>
    );
}