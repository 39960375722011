import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

export const ascOrder = 'ascOrder';
export const descOrder = 'descOrder';

export const choiceInput = (
  field,
  isRequired,
  isReadOnly,
  placeholder,
  value,
  defaultValue,
  lookup,
  handleChange,
  sortBy,
  style,
) => {
  return (
    <Input
      id={field}
      key={field}
      style={style}
      required={isRequired}
      placeholder={placeholder}
      type="select"
      value={value ? value : ''}
      onChange={(e) => handleChange(field, e.target.value)}
      disabled={isReadOnly}
    >
      <option key={0} value="" disabled defaultValue hidden>
        {placeholder ? placeholder : ''}
      </option>
      {defaultValue && (
        <option key={defaultValue.key}>{defaultValue.value}</option>
      )}
      {Object.entries(lookup)
        .sort(([aKey, aValue], [bKey, bValue]) => {
          return sortBy === ascOrder
            ? aValue.toString().localeCompare(bValue.toString())
            : sortBy === descOrder
              ? bValue.toString().localeCompare(bValue.toString())
              : aValue; //no sort
        })
        .map(([key, value]) => renderChoiceOption(key, value))}
    </Input>
  );
};

const renderChoiceOption = (key, text) => {
  return (
    <option key={key} value={key}>
      {text}
    </option>
  );
};

export default function Choice(props) {
  const {
    field,
    title,
    value,
    isRequired,
    lookup,
    defaultValue,
    isReadOnly,
    placeholder,
    handleChange,
    Helper,
    sortBy = ascOrder,
  } = props;

  return (
    <FormGroup key={'form' + field} controlid={field}>
      {title && <Label for={field}>{isRequired ? title + '*' : title}</Label>}
      <span style={{ marginLeft: '3px' }}>{Helper}</span>
      {choiceInput(
        field,
        isRequired,
        isReadOnly,
        placeholder,
        value,
        defaultValue,
        lookup,
        handleChange,
        sortBy,
      )}
    </FormGroup>
  );
}
