import React from "react";
import Button from "reactstrap/lib/Button";

const Submit = (props) => {
    return (
        <Button
            key={"form" + props.field}
            type="submit"
            variant="primary"
            className="float-right btn btn-success"
            disabled={props.isReadOnly}
        >
            {props.title}
        </Button>
    );
};

export default Submit;
