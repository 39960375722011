import React, { useState } from "react";
import Image from "react-bootstrap/Image";
import { defaultCvImage } from "utils/fileloader";
import LoadingOverlay from "react-loading-overlay";
import { CameraIcon } from "../Icons/icons";
import { Dialog, dialogType } from "../../Dialogs/Dialog";
import Cropper from "../../Cropper/Cropper";

const ImageCustom = (props) => {
  const { handleUpload, content, url } = props;
  const fileInputRef = React.createRef();
  const [hover, toggleHover] = useState(false);
  const [show, setShow] = useState(false);
  const toggleDialog = () => setShow(!show);
  const [pictureName, setPictureName] = useState();
  const [image, setImage] = useState();
  const [key, setKey] = useState(0);

  const onPictureChange = (e) => {
    if (!e.target.files[0]) {
      return { pictureName: null, pictureContent: defaultCvImage };
    } else {
      e.preventDefault();
      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }

      setPictureName(files[0].name);

      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        toggleDialog();
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const onSavePicture = (base64) => {
    handleUpload({ name: pictureName, content: base64 })
    setKey(key + 1);
    toggleDialog();
  };
  return (
    <>
      <div
        className="hover"
        onMouseEnter={() => toggleHover(true)}
        onMouseLeave={() => toggleHover(false)}
      >
        <LoadingOverlay
          styles={{
            overlay: (base) => ({
              ...base,
              "border-radius": "50%",
              width: "124px"
            }),
          }}
          active={hover}
          spinner={
            <React.Fragment>
              <CameraIcon onClick={() => fileInputRef.current.click()} />
            </React.Fragment>
          }
        >
          <Image
            src={url ?? content ?? defaultCvImage}
            alt="profile"
            roundedCircle
            style={{
              width: "124px",
              height: "124px",
              border: "solid",
              borderColor: "white",
            }}
          />
        </LoadingOverlay>
      </div>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        key={key}
        onChange={onPictureChange}
        hidden
      />
      {show && (
        <Dialog
          type={dialogType.large}
          handleClose={toggleDialog}
          component={
            <Cropper
              image={image}
              handleSave={onSavePicture}
              handleCancel={toggleDialog}
            />
          }
          headerless={true}
        />
      )}
    </>
  );
};

export default ImageCustom;
