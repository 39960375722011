import React, { useEffect, useState } from "react";
import { Card, CardBody, Button } from "reactstrap";
import Buttons from "components/Ui/Button";
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  // Button,
  Box,
} from "@material-ui/core";
import GenericHeader from "components/Headers/GenericHeader";
import Overview from "./OverviewStep";
import ProfessionalsDataStep from "./ProfessionalsDataStep";
import DifferentiationStep from "./DifferentiationStep";
import OtherInformationStep from "./OtherInformationStep";
import FinalStep from "./FinalStep";
import { customerWebsiteService } from "utils/_services/customerWebsite.service";
import { userService } from "utils/_services/user.service";
import { NotificationManager } from "react-notifications";
import useFetchUser from "hooks/useFetchUser";

const steps = [
  "Informations entreprise",
  // "Problèmes et solutions",
  "Domaine de differentiation",
  "Autres informations...",
];

export default function MainStepper() {
  const [activeStep, setActiveStep] = React.useState(-1);
  const [skipped, setSkipped] = React.useState(new Set());
  const [websiteData, setwebsiteData] = useState({});
  const user = useFetchUser();

  useEffect(() => {
    customerWebsiteService
      .getCustomerWebsite()
      .then((response) => setwebsiteData(response.data))
      .catch((err) => console.log(err));
  }, []);

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (name === "hostNameSite") {
      value = value.replace(/\s/g, '');
    }
    setwebsiteData({
      ...websiteData,
      [name]: value,
    });
  };

  const isStepOptional = (step) => {
    return false; //step === 3;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      customerWebsiteService.manageCustomerWebsite(
        websiteData
      )
        .then((response) => {
          if (response.data.value === true) {
            NotificationManager.success("Votre site à été créé");
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          } else {
            NotificationManager.error(response.data.message);
            return;
          }
        })
        .catch((err) => console.log(err));
    }

    if (activeStep === steps.length) {
      window.open(`https://${websiteData.hostNameSite}.btpgo.fr`, "_blank");
      return;
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep !== steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const getNextButtonLabel = () => {
    switch (activeStep) {
      case -1:
        return "Commencer";
      case steps.length - 1:
        return "Créer";
      case steps.length:
        return "Voir mon site";
      default:
        return "Suivant";
    }
  };

  const getComponent = () => {
    switch (activeStep) {
      case -1:
        return <Overview />;
      case 0:
        return (
          <ProfessionalsDataStep
            websiteData={websiteData}
            onChange={handleInputChange}
          />
        );
      // case 1:
      //   return (
      //     <ProblemSlovingStep
      //       websiteData={websiteData}
      //       onChange={handleInputChange}
      //     />
      //   );
      case 1:
        return (
          <DifferentiationStep
            websiteData={websiteData}
            onChange={handleInputChange}
          />
        );
      case 2:
        return (
          <OtherInformationStep
            websiteData={websiteData}
            onChange={handleInputChange}
          />
        );
      case 3:
        return (
          <FinalStep
          // websiteData={websiteData} onChange={handleInputChange}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      <GenericHeader />
      {user &&
        (user.company != null ? (
          <>
            {activeStep > -1 && (
              <Stepper className="m-2" activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if (isStepOptional(index)) {
                    labelProps.optional = (
                      <Typography variant="caption">Optional</Typography>
                    );
                  }
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            )}

            <React.Fragment>
              <Card className="shadow m-2">
                <CardBody className="m-2">
                  {getComponent()}
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    {activeStep > -1 && (
                      <Button
                        className="my-3"
                        color="danger"
                        outline
                        role="button"
                        disabled={activeStep === -1}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Retour
                      </Button>
                    )}
                    <Box sx={{ flex: "1 1 auto" }} />
                    {isStepOptional(activeStep) && (
                      <Button
                        color="inherit"
                        onClick={handleSkip}
                        sx={{ mr: 1 }}
                      >
                        Passer
                      </Button>
                    )}
                    <Buttons
                      buttonText={getNextButtonLabel()}
                      onClick={handleNext}
                    />
                  </Box>
                </CardBody>
              </Card>
            </React.Fragment>
            {/* )} */}
          </>
        ) : (
          <div
            class="alert alert-warning mx-2 my-2 mx-md-6 my-md-9"
            role="alert"
          >
            <h2>
              Vous devez remplir les informations de votre entreprise dans votre
              profil afin de pouvoir utiliser cette fonctionnalité.
            </h2>
          </div>
        ))}
    </>
  );
}
