import * as subjects from "../../constants/subjects";
import * as permissions from "../constants/permissions";
import { CanHandle } from "./common";
// import { store } from "../../../store/index.js";

export const defineRulesForBilling = (can, roles) => {
  if ([permissions.ALL, permissions.BTPGO_ADMIN].some((role) => roles.includes(role))) {
    can(permissions.MANAGE, subjects.BILLING);
  } else {
    roles.forEach((perm) => {
      can(perm, subjects.BILLING);
    });
  }
};

export const CanAddBill = () => {
  return CanHandle(permissions.BILLING_ADD, subjects.BILLING);
}

// export const canUpdateBill = () =>
//   store
//     .getState()
//     .authentication.user.ability.can(
//       permissions.BILLING_UPDATE,
//       subjects.BILLING
//     );

// export const canDeleteBill = () =>
//   store
//     .getState()
//     .authentication.user.ability.can(
//       permissions.BILLING_DELETE,
//       subjects.BILLING
//     );
