export const filterObjectPropertiesByarray = (item, filterArray) => {
  if (filterArray.length === 0) return item;
  const filtered = Object.keys(item)
    .filter((key) => !filterArray.includes(key))
    .reduce((obj, key) => {
      obj[key] = item[key];
      return obj;
    }, {});

  return filtered;
};

export const filterArrayByCriteria = (array, criteria) => {
  const re = RegExp(`.*${criteria.toLowerCase()}.*`);
  return array.filter((v) => v.toLowerCase().match(re));
};
