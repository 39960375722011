import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReviewForm from 'components/Review/AddReviewForm';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import GenericHeader from 'components/Headers/GenericHeader';
import { companyService } from 'utils/_services/company.service';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'features/slices/userSlice';
import { getUserStatus } from 'features/slices/userSlice';
import { useDispatch } from 'react-redux';
import { fetchUser } from 'features/slices/userSlice';
import { ConfirmationMessage } from 'components/Generic/confirmationMessage';

function AddReviewPage() {
    const { companyId } = useParams();
    const [companyName, setCompanyName] = useState('');
    const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
    const user = useSelector(getCurrentUser);
    const userStatus = useSelector(getUserStatus);
    const dispatch = useDispatch()

    useEffect(() => {
        if (userStatus === "idle") {
            dispatch(fetchUser());
        }
    }, [userStatus, dispatch]);

    useEffect(() => {
        companyService
            .getCompanyById(companyId)
            .then((response) => setCompanyName(response.data.name))
            .catch((err) => console.log(err));
    }, [companyId]);


    const onSubmitSuccess = () => {
        setIsSubmitSuccess(true);
    }

    return (
        <>
            <GenericHeader />
            <Container className="mt-2">
                {isSubmitSuccess === true && <ConfirmationMessage mainMessage={"Votre avis a été créé avec succès"} />}
                {!companyName && <h1>La page que vous cherchez n'éxiste pas</h1>}
                {companyName && user && isSubmitSuccess === false &&
                    <Card className="shadow mb-2">
                        <CardHeader>
                            <h2>Ajouter un avis à l'entreprise "{companyName}"</h2>
                        </CardHeader>
                        <CardBody>
                            <ReviewForm
                                companyId={companyId}
                                userId={user.id}
                                onSubmitSuccess={onSubmitSuccess}
                            /></CardBody>
                    </Card>
                }
            </Container>
        </>

    );
}

export default AddReviewPage;
