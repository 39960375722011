import React from "react";
import { Link } from "react-router-dom";
import { NavItem, NavLink } from "reactstrap";
import "./Welcome.css";

const LinkNavbar = () => {
  const navItems = [
    {
      id: 1,
      icon: "ni ni-key-25",
      text: "Professionnel BTP",
      to: "/welcomeArtisans",
    },
    {
      id: 2,
      icon: "ni ni-circle-08",
      text: "Client",
      to: "/welcomeProperty",
    },
    // {
    //   id: 3,
    //   // icon: "ni ni-circle-08",
    //   text: "Blog",
    //   to: "/blog",
    // },
  ];

  return (
    <div className="d-flex flex-row-reverse">
      {navItems.map((item) => (
        
        <NavItem key={item.id} className="listStyle mr-7">
          <NavLink className="nav-link-icon" to={item.to} tag={Link} style={{}}>
            {/* <i className={item.icon} /> */}
            <span className="nav-link-inner--text">{item.text}</span>
          </NavLink>
        </NavItem>
      ))}
    </div>
  );
};

export default LinkNavbar;