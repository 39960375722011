import React, { useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, FormGroup, Input, Row, Col } from "reactstrap";
import "./profil.css";
import GenerateFormInput from "components/Ui/GenerateFormInput";
import GenerateFormInputWithLimit from "components/Ui/GenerateFormInputWithLimit";
import SearchJob from "components/Search/SearchJob";
import CheckboxesGroup from "./CheckboxesGroup";
import { AiOutlineHeart, AiOutlineProfile } from "react-icons/ai";
import { BiPhoneCall, BiMailSend } from "react-icons/bi";
import { FaMapPin, FaMapMarkerAlt, FaCamera } from "react-icons/fa";
import { GiLightBulb } from "react-icons/gi";
import defaultProfilePicture from "assets/img/brand/ImageNotFound.png";

import image from "assets/img/blog/fond.jpeg";

import SearchCity from "components/Search/SearchCity";
import { skillsService } from "utils/_services/skills.service";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllworkAvailabilities,
  fetchWorkAvailabilities,
  getWorkAvailabilitiesStatus,
} from "features/slices/workAvailabilitySlice";

import { updateUser } from "features/slices/userSlice";
import MultiselectAddons from "components/Search/MultiselectAddons";
import Dialog from "components/Modal/Dialog";
import AddData from "views/profile/addData";
import { v4 } from "uuid";

const EditProfilePro = ({ userData }) => {
  const [user, setUser] = useState(userData);
  const [skillsOptions, setSkillsOptions] = useState([]);
  const [modal, setModal] = useState(false);
  const toggleAddSkills = () => setModal(!modal);
  const skillsRef = useRef();

  const hiddenFileInput = React.useRef(null);

  const onHandleAddon = () => {
    toggleAddSkills();
  };

  const onSubmitAddSkill = (value) => {
    const editedSkillsOptions = [...skillsOptions];
    const newSkill = {
      // id: v4(),
      description: value,
      state: "temporary"
    }
    editedSkillsOptions.push(newSkill);

    setSkillsOptions(editedSkillsOptions);

    const editedUser = { ...user };
    editedUser.skills = [...editedUser.skills, newSkill];
    //editedUser.skills.push(newSkill)
    setUser(editedUser);

    skillsRef.current.onSelectChange(editedUser.skills);

    toggleAddSkills();
  }

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const workAvailabilities = useSelector(getAllworkAvailabilities);
  const workAvailabilitiesStatus = useSelector(getWorkAvailabilitiesStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (workAvailabilitiesStatus === "idle") {
      dispatch(fetchWorkAvailabilities());
    }
  }, [workAvailabilitiesStatus, dispatch]);

  useEffect(() => {
    if (userData.jobId) {
      skillsService.getSkillsByJobId(userData.jobId).then((res) => {
        setSkillsOptions(res.data);
      });
    }
  }, [])

  const onChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUser(user)).unwrap();
  };

  const changeSelectdCityValue = (city) => {
    if (city != null) {
      const { id } = city;
      setUser((prevState) => ({
        ...prevState,
        communeId: id,
      }));
    }
  };

  const changeSkilsValue = (skils) => {
    setUser((prevState) => ({
      ...prevState,
      skills: skils,
    }));
  };

  const changeSelectdJobValue = (job) => {
    if (job) {
      const { label, value } = job;
      setUser((prevUser) => ({
        ...prevUser,
        job: {
          ...prevUser.job,
          id: value,
          title: label,
        },
        skills: [],
      }));

      skillsService.getSkillsByJobId(value).then((res) => {
        setSkillsOptions(res.data);
      });
    }
  };

  const handleWorkAvailabilitiesChange = (e) => {
    var userAvailabilities = Object.assign([], user.workAvailabilities);
    let selectedId = parseInt(e.target.name);

    if (e.target.checked) {
      userAvailabilities.push({ id: selectedId });
    } else {
      userAvailabilities = userAvailabilities.filter(function (obj) {
        return obj.id !== selectedId;
      });
    }
    setUser({
      ...user,
      workAvailabilities: userAvailabilities,
    });
  };


  return (
    <>
      <Card className="shadow mb-2">
        <CardBody>
          <div className="pl-lg-4">

            <Row>
              <div className="col-md-7">
                <Row>
                  <div className="col-md-6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-address"
                      >
                        Profession
                      </label>
                      {Object.keys(user).length !== 0 && (
                        <SearchJob
                          changeSelectdJobValue={changeSelectdJobValue}
                          selectedJobValue={user.job?.id}
                        />
                      )}
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-address"
                      >
                        Vous rechercher ?
                      </label>
                      <Input
                        className={
                          true
                            ? "pointer inputGenerateBorder"
                            : "not-allowed inputGenerateBorder"
                        }
                        type="select"
                        disabled={false}
                        name="userSearchType"
                        defaultValue={user.userType}
                        onChange={onChange}
                      >
                        <option value="0"> Des clients</option>
                        <option value="1"> Des professionnels</option>
                        <option value="2">
                          Des clients et des professionnels
                        </option>
                      </Input>
                    </FormGroup>
                  </div>
                </Row>
                <Row>
                  <div className="col-md-6">
                    <FormGroup>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="search-city"
                        >
                          Ma ville d'activité
                        </label>

                        <div
                          style={{
                            color: "rgb(111,174,222)",
                            marginLeft: "7px",
                          }}
                        >
                          <FaMapPin
                            className="mb-2"
                            fontSize="1rem"
                            color="rgb(111,174,222)"
                          />
                        </div>
                      </div>
                      {Object.keys(user).length !== 0 && (
                        <SearchCity
                          changeSelectdCityValue={changeSelectdCityValue}
                          selectedCityValue={user.commune?.id}
                          titleField="Ville d'activité"
                        />
                      )}
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <GenerateFormInput
                      forInput="input-email"
                      label="Dans un rayon (en km)"
                      icon={
                        <FaMapMarkerAlt
                          className="mb-2"
                          fontSize="1rem"
                          color="rgb(111,174,222)"
                        />
                      }
                      editModeClassName={true}
                      value={user.limitInterventionZone}
                      onChange={onChange}
                      name="limitInterventionZone"
                      type="number"
                      placeholder="100 km"
                      disabled={false}
                    />
                  </div>
                </Row>
                {workAvailabilitiesStatus === "succeeded" && (
                  <Row>
                    <Col lg="12">
                      <CheckboxesGroup
                        defaultItems={workAvailabilities}
                        selectedItems={user.workAvailabilities}
                        onChange={handleWorkAvailabilitiesChange}
                        title="Vos disponibilitées"
                      />
                    </Col>
                  </Row>
                )}
              </div>
              <div className="col-md-5">
                <GenerateFormInput
                  className="marginProfile"
                  label="Savoir faire"
                  icon={
                    <GiLightBulb
                      className="mb-2"
                      fontSize="1rem"
                      color="rgb(111,174,222)"
                    />
                  }
                />
                {/* <Skils
                    changeSkilsValue={changeSkilsValue}
                    selectedSkilsValue={user.skills}
                    disabledCompany={false}
                    editModeCompany={true}
                    options={skillsOptions}
                  /> */}

                <MultiselectAddons
                  ref={skillsRef}
                  field={"skills"}
                  displayValue={"description"}
                  addonText={"Votre savoir faire pas dans la liste ? ajouter le"}
                  // title={"Savoir faire"}
                  isRequired={true}
                  isReadOnly={false}
                  selectionLimit={5}
                  placeholder="Cliquer ici pour ajouter"
                  handleChange={changeSkilsValue}
                  handleAddon={onHandleAddon}
                  addonTooltip={"nouveau savoir faire"}
                  selectedValues={user.skills}
                  options={skillsOptions}
                />

                <GenerateFormInputWithLimit
                  forInput="input-howIAm"
                  label="Présentation"
                  editModeClassName={true}
                  value={user.howIAmDescription}
                  onChange={onChange}
                  className="mt-4"
                  name="howIAmDescription"
                  placeholder="Présentation"
                  maxlength={2000}
                  rowsCount={3}
                  type="textarea"
                  icon={
                    <AiOutlineProfile
                      className="mb-2"
                      fontSize="1rem"
                      color="rgb(111,174,222)"
                    />
                  }
                  disabled={false}
                />
              </div>

            </Row>
            <div className="d-flex justify-content-end">
              <Button
                className="my-3 btn btn-success"
                title="Sauvegarder"
                onClick={onSubmit}
              >
                Sauvegarder
              </Button>
            </div>
          </div>
        </CardBody>
      </Card>
      {modal && (
        <Dialog
          title="Nouveau savoir-faire"
          component={
            <AddData
              onSubmit={onSubmitAddSkill}
              onCancel={toggleAddSkills}
              name={"newSkill"}
              placeholder={"Nouveau savoir-faire"}
            // onChange={}
            />
          }
          handleClose={toggleAddSkills}
        />
      )}
    </>
  );
};

export default EditProfilePro;
