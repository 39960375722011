import React, { useState } from "react";
import { Card, Container, Row, Button } from "reactstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import image from "../../assets/img/brand/ImageNotFound.png";
import { BsFillPhoneVibrateFill } from "react-icons/bs";
import { BiPhoneCall, BiMailSend } from "react-icons/bi";
import CarouselImg from "./CarouselImg";
import "./search.css";
import CustomerClient from "./CustomerClient";
import Buttons from "components/Ui/Button";
import SearchCompany from "./SearchCompany";
import SearchWorkeur from "./SearchWorkeur";
import ViewProfile from "components/Profile/ViewProfile";
import SearchTeams from "./SearchTeams";
const UsersSearch = ({ user }) => {
  const [value, onChange] = useState(new Date());

  const formattedPhoneNumber = user.phoneNumber
    ?.replace(/\s/g, "")
    ?.replace(/(\d{2})/g, "$1.")
    ?.slice(0, -1);
  return (
    <>
      <Container className="p-3">
        <Card className="shadow border-0">
          <ViewProfile searchedUser={user} />
        </Card>
      </Container>
    </>
  );
};

export default UsersSearch;
