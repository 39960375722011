import { useEffect, useState } from "react";
import { Button, Card, CardBody, Form, Row, Col, FormGroup } from "reactstrap";
import {
  RiBuilding4Fill,
  RiGroupFill,
  RiMoneyEuroCircleLine,
} from "react-icons/ri";
import { NotificationManager } from "react-notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

import { companyService } from "utils/_services/company.service";

import Image from "components/Forms/others/Image";

import GenerateFormInput from "components/Ui/GenerateFormInput";
import Dialog from "components/Modal/Dialog";
import ButtonAddons from "components/Search/ButtonAddons";
import DeleteIconButtonWithModal from "components/Buttons/DeleteIconButtonWithModal";
import ManageInsurance from "views/profile/manageInsurance";
import { v4 } from "uuid";
import { convertStringToBase64 } from "utils/converter";
import SignatureScreen from "components/Signature/SignatureScreen";
import { url } from "caniuse-lite/data/features";


const EditCompany = ({ companyData, userId }) => {
  const [company, setCompany] = useState({});
  const [originalCompany, setOriginalCompany] = useState({});
  const [modal, setModal] = useState(false);
  const [selectedInsurance, setSelectedInsurance] = useState({});
  const toggleManageInsurance = () => setModal(!modal);

  const siretNumberCharactersCount = 14;

  useEffect(() => {
    if (companyData?.id) {
      companyService
        .getCompanyById(companyData.id)
        .then((res) => {
          res.data.insurances?.map(x => x.guid = v4());
          setCompany(res.data);
          setOriginalCompany(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [companyData]);

  const onHandleAddon = () => {
    setSelectedInsurance({});
    toggleManageInsurance();
  };

  const onSubmitManageInsurance = (value) => {
    const editedCompany = { ...company };

    if (editedCompany.insurances != null) {
      const insuranceIndex = editedCompany.insurances.findIndex(insurance => insurance.guid === value.guid);

      if (insuranceIndex > -1) {
        // Update existing insurance
        editedCompany.insurances[insuranceIndex] = value;
      } else {
        // Add new insurance
        editedCompany.insurances.push(value);
      }
    } else {
      // Initialize insurances array with the new insurance
      editedCompany.insurances = [value];
    }

    setCompany(editedCompany);

    toggleManageInsurance();

    onSubmit();
  }

  const manageInsurance = (currentInsurance) => {
    setSelectedInsurance(currentInsurance);
    toggleManageInsurance();
  }
  const onDeleteInsurance = (value) => {
    const editedCompany = { ...company };
    editedCompany.insurances = editedCompany.insurances.filter(item => item.guid !== value);
    setCompany(editedCompany);
    onSubmit();
  };

  const onSubmit = () => {
    const editedCompany = { ...company };
    updateCompany(editedCompany);
  };

  const updateCompany = (editedCompany) => {
    if (editedCompany.logo?.content) {
      editedCompany.logo.content = convertStringToBase64(editedCompany.logo.content)
    }

    if (editedCompany?.siretNumber === undefined || editedCompany?.siretNumber === "") {
      NotificationManager.error("Le numéro de siret est obligatoire");
      return;
    }

    if (editedCompany?.id && editedCompany.id > 0) {
      companyService
        .updateCompany(editedCompany)
        .then((res) => {
          NotificationManager.success(
            "Votre entreprise a été mis a jour avec succes"
          );
          setOriginalCompany(res.data.value);
        })
        .catch((err) => {
          NotificationManager.error(
            "Un problème est survenu lors de la mise à jour de votre entreprise."
          );
        });
    } else {
      companyService
        .addCompany(editedCompany, userId)
        .then((res) => {
          const data = res.data;
          if (data.resultState === 0) {
            NotificationManager.error(data.message);
          } else {
            NotificationManager.success(
              "Votre entreprise a été mis a jour avec succes"
            );
            setOriginalCompany(data.value);
            setCompany(data.value);
          }
        })
        .catch((err) => {
          NotificationManager.error(
            "Un problème est survenu lors de la mise à jour de votre entreprise."
          );
        });
    }
  }

  const onSavePicture = ({ name, content }) => {

    const editedCompany = { ...originalCompany };
    editedCompany.logo = {
      content: content,
      name: name,
      url: null
    };

    setCompany((prevCompany) => ({
      ...prevCompany,
      logo: {
        ...prevCompany.logo,
        content: content,
        name: name,
        url: null
      },
    }));

    updateCompany(editedCompany);
  };

  const onChangeSignature = ({ name, content }) => {

    const editedCompany = { ...originalCompany };
    if (content === null) {
      setCompany((prevCompany) => ({
        ...prevCompany,
        signature: null
      }));
      return;
    }

    setCompany((prevCompany) => ({
      ...prevCompany,
      signature: {
        ...prevCompany.signature,
        content: content,
        name: name ? name : `signature-${editedCompany.siretNumber}.png`,
      },
    }));

  };

  const onChange = (e) => {
    setCompany({
      ...company,
      [e.target.name]: e.target.value,
    });
    const validSiret = e.target.name === "siretNumber" &&
      // company.name === "" &&
      e.target.value != null &&
      e.target.value.length === siretNumberCharactersCount;

    if (validSiret === true) {
      companyService
        .getCompanyBySiretNumber(e.target.value)
        .then((res) => {
          setCompany({
            ...company,
            [e.target.name]: e.target.value,
            name: res.data,
          });
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <Card className="shadow">
        <CardBody>
          <div className="pl-lg-4">
            <Form onSubmit={onSubmit}>
              <div style={{
                maxWidth: "140px",
                marginRight: "auto",
                marginLeft: "auto"
              }}>
                <FormGroup>
                  <div>
                    <label className="form-control-label">
                      Logo de l'entreprise
                    </label>
                  </div>
                  <div >
                    <Image handleUpload={onSavePicture} content={company.logo?.content} url={company.logo?.url} />
                  </div>
                </FormGroup>
              </div>
              <Row>
                <Col md="3" sm="6">
                  <GenerateFormInput
                    forInput="input-siret"
                    label="Numéro de Siret"
                    // invalid={
                    //   company?.siretNumber === undefined ||
                    //   company?.siretNumber === ""
                    // }
                    // invalidMessage="Le numéro de siret est obligatoire."
                    editModeClassName={true}
                    value={company?.siretNumber}
                    onChange={onChange}
                    name="siretNumber"
                    placeholder="siret"
                    type="text"
                    required={true}
                    icon={
                      <RiBuilding4Fill
                        className="mb-2"
                        fontSize="1rem"
                        color="rgb(111,174,222)"
                      />
                    }
                    disabled={false}
                  />
                </Col>
                <Col md="3" sm="6">
                  <GenerateFormInput
                    forInput="input-city"
                    label="Nom de l'Entreprise"
                    editModeClassName={true}
                    value={company?.name}
                    onChange={onChange}
                    name="name"
                    placeholder="Entreprise"
                    type="text"
                    icon={
                      <RiBuilding4Fill
                        className="mb-2"
                        fontSize="1rem"
                        color="rgb(111,174,222)"
                      />
                    }
                    disabled={false}
                  />
                </Col>
                <Col md="3" sm="6">
                  <GenerateFormInput
                    forInput="input-email"
                    label="Nombre d'employé(s)"
                    icon={
                      <RiGroupFill
                        className="mb-2"
                        fontSize="1rem"
                        color="rgb(111,174,222)"
                      />
                    }
                    editModeClassName={true}
                    value={company?.employeesNumber}
                    onChange={onChange}
                    name="employeesNumber"
                    type="number"
                    placeholder=""
                    disabled={false}
                  />
                </Col>
                <Col md="3" sm="6">
                  <GenerateFormInput
                    forInput="input-email"
                    label="Chiffre d'affaire"
                    icon={
                      <RiMoneyEuroCircleLine
                        className="mb-2"
                        fontSize="1rem"
                        color="rgb(111,174,222)"
                      />
                    }
                    editModeClassName={true}
                    value={company?.turnover}
                    onChange={onChange}
                    name="turnover"
                    type="number"
                    placeholder=""
                    disabled={false}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="6" sm="12">
                  <label className="form-control-label">
                    Vos assurances
                  </label>
                  {/* <FormGroup switch className="d-flex flex-row m-2">
                    <label className="custom-toggle">
                      <input type="checkbox"
                        name="rcpInsurance"
                        checked={company?.rcpInsurance}
                        onChange={(e) => {
                          onChange({
                            target: {
                              name: e.target.name,
                              value: e.target.checked,
                              }
                          });
                          }} />
                          <span className="custom-toggle-slider cursor-pointer rounded-circle" />
                          </label>
                          
                    <Label check
                      style={{
                        color: "black",
                        fontWeight: "600"
                        }}
                      className="ml-3">Assurance RCP et décennale</Label>
                      </FormGroup> */}
                  {company && company.insurances != null && <ul> {company.insurances.map((value, index) =>
                    <li className="font-weight-bolder text-dark mb-2" key={`insurance-${index}`}>{value.title}
                      <DeleteIconButtonWithModal
                        modalTitle={"Supprimer une assurance"}
                        modalText={"êtes-vous sûr de vouloir supprimer cette assurance ?"}
                        deleteAction={() => onDeleteInsurance(value.guid)}
                        className={"ml-3 cursor-pointer"}
                      ></DeleteIconButtonWithModal>
                      <span>
                        <FontAwesomeIcon
                          title="Modifier"
                          icon={faPenToSquare}
                          className="ml-2 cursor-pointer"
                          color="gray"
                          onClick={() => manageInsurance(value)}
                        />
                      </span>
                    </li>
                  )}
                  </ul>}
                  {(company?.insurances === null || company.insurances === undefined || company.insurances.length < 3) &&
                    <div className="mt-2">
                      <ButtonAddons addonText={"Nouvelle assurance"} addonTooltip={"Nouvelle assurance"} handleAddon={onHandleAddon} />
                    </div>
                  }
                </Col>
                <Col md="6" sm="12">
                  <SignatureScreen
                    onChangeImage={onChangeSignature}
                    imageURL={company?.signature?.url}
                    content={company?.signature?.content} />
                </Col>
              </Row>
              {/* <Row><SignatureScreen /></Row> */}
            </Form>
            <div className="d-flex justify-content-end">
              <Button
                className="my-3 btn btn-success"
                title="Sauvegarder"
                onClick={onSubmit}
              >
                Sauvegarder
              </Button>
            </div>
          </div>
        </CardBody>
      </Card >
      {modal && (
        <Dialog
          title="Assurance"
          component={
            <>
              <ManageInsurance
                onSubmit={onSubmitManageInsurance}
                onCancel={toggleManageInsurance}
                name={"new"}
                placeholder={"Nouvelle assurance"}
                rowsCount={2}
                maxlength={200}
                insurance={selectedInsurance}
              />
            </>
          }
          handleClose={toggleManageInsurance}
        />
      )
      }
    </>
  );
};

export default EditCompany;
