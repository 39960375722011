import React from "react";
import { Form } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";

const SwitchWithText = (props) => {
  return (
    <Tooltip title={props.placeholder}>
      <Form.Group key={"form" + props.field} controlId={props.field} style={{ marginBottom: "0" }}>
        {props.label && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <label className="form-control-label" htmlFor={props.forInput}>
              {props.label}{props.required && ("*")}
            </label>
            {props.icon && (
              <div style={{ color: "rgb(111,174,222)", marginLeft: "7px" }}>
                {props.icon}
              </div>
            )}
          </div>)}
        <div className="mt-2">
          <label className="custom-toggle">
            <input type="checkbox" name={props.name} checked={props.value && (props.value === true || props.value === "on")} readOnly={false} onChange={props.handleChange} />
            <span  id={props.field} className="custom-toggle-slider" />
          </label>
          <span style={{ position: "absolute", paddingLeft: "15px" }}>   {props.value && (props.value === true || props.value === "on") ? props.checkedText : props.unCheckedText}</span>
        </div>
      </Form.Group>
    </Tooltip>
  );
};

export default SwitchWithText;
