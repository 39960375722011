import { useEffect, useState } from "react";
import "react-notifications/lib/notifications.css";
import { Progress, Badge } from "reactstrap";
import { Tabs, Tab, Box } from "@material-ui/core";
import { Container } from "reactstrap";
import UserHeader from "components/Headers/UserHeader.js";
import GenericHeader from "components/Headers/GenericHeader";
import PhotoUploader from "components/Photo/PhotoUploader";

import ViewProfile from "components/Profile/ViewProfile";
import EditProfile from "components/Profile/EditProfile";
import EditProfilePro from "components/Profile/EditProfilePro";
import EditCompany from "components/Profile/EditCompany";

import Buttons from "components/Ui/Button";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserStatus,
  getCurrentUser,
  fetchUser,
} from "features/slices/userSlice";
import ViewPublicProfile from "components/Profile/ViewPublicProfile";
import EditCompanyCommunication from "components/Profile/EditCompanyCommunication";
import ReviewsPage from "views/Review/ReviewsPage";
import ScrolledTabs from "components/Tab/ScrolledTabs";

const Profile = () => {
  const { userData } = useParams();

  const user = useSelector(getCurrentUser);
  const userStatus = useSelector(getUserStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userStatus === "idle") {
      dispatch(fetchUser());
    }
  }, [userStatus, dispatch]);

  const isNumeric = (string) => Number.isFinite(+string);

  return (
    <>
      {/* {userStatus === "succeeded" && */}
      {user &&
        (userData && user.id === userData ? (
          <>
            <GenericHeader />
            <div className="d-flex justify-content-center">
              <Buttons
                linkTo={"/ap/user-profile"}
                buttonText="Modifier mon profil"
              />
            </div>

            <Container className="mt-2">
              <ViewProfile searchedUser={user} />
            </Container>
          </>
        ) : (isNumeric(userData) ?
          <>
            <GenericHeader />
            <ViewPublicProfile />
          </>
          : (
            <>
              <UserHeader currentUser={user} />
              <Container>
                <div className="progress-wrapper">
                  <div className="progress-info row">
                    <div className="progress-label mb-2 col-md-5 col-sm-11 text-lg-left text-center">
                      <span className="text-sm">
                        Profil rempli à{" "}
                        <strong>{user.profileFilledPercentage} %</strong>
                      </span>
                    </div>
                    <div className="col-md-5 mb-2 col-sm-11 text-center  text-lg-right">
                      <Badge
                        className="text-sm "
                        color={user.status === "Validated" ? "success" : "danger"}
                        href="#"
                        onClick={(e) => e.preventDefault()}
                      >
                        Profil{" "}
                        {user.status === "Validated"
                          ? "validé"
                          : "en cours de validation"}
                      </Badge>
                    </div>
                  </div>
                  <Progress
                    max="100"
                    value={user.profileFilledPercentage}
                    color="success"
                  />
                </div>
                <ScrolledTabs childrens={[
                  { title: "Perso", component: <EditProfile userData={user} /> },
                  { title: "Pro", component: <EditProfilePro userData={user} /> },
                  { title: "Entreprise", component: <EditCompany companyData={user.company} userId={user.id} /> },
                  { title: "Communication", component: <EditCompanyCommunication companyData={user.company} userId={user.id} /> },
                  { title: "Galerie", component: <PhotoUploader /> },
                  { title: "Avis", component: user.company && <ReviewsPage companyId={user.company?.id} companyName={user.company?.name} /> },
                ]} />

              </Container>
            </>
          )))}
    </>
  );
};

export default Profile;
