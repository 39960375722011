import React from "react";
import UsersTable from "../../../../components/Tables/MaterialTable";
import {
  email,
  firstName,
  lastName,
  lastConnectionDate,
  job,
  city,
  switchFieldName,
  switchisFreeFieldName,
  profileStatus,
  showInSearch,
  roles,
} from "../Model";
import { Card, CardBody } from "reactstrap";

const Users = (props) => {
  const { users, isLoading, handleRowClick, handleChangeClick, handleDeleteClick } = props;

  const onRowClick = (index, event) => {
    if (
      ![switchFieldName, switchisFreeFieldName].some((element) => {
        const regex = new RegExp("^" + element + "-");
        return regex.test(event.target.htmlFor) || regex.test(event.target.id);
      })
    )
    {
      handleRowClick(index);
    }
  };

  const onChangeClick = (field, id, value) => {
    const index = users.findIndex((user) => user.id === id);
    if (index > -1) handleChangeClick(index, field, value);
  };

  return (
    <>
    <Card>
    <CardBody>
      <UsersTable
        id={"users"}
        columns={[
          profileStatus(onChangeClick),
          showInSearch(onChangeClick),
          firstName,
          lastName,
          email,
          city,
          job,
          roles,
          // startDate,
          lastConnectionDate,
        ]}
        data={users}
        isLoading={isLoading}
        handleRowClick={onRowClick}
        handleDelete={handleDeleteClick}
        rowId={"id"}
        handleFiltering={true}
      />
      </CardBody>
    </Card>
    </>
  )
}

export default Users;
